$(function() {
	
    $("#nav-toggle").click(function(){
		$("#mobil-menu").toggleClass("open");
		$(this).toggleClass("open");
	});
    
    $("#mobil-menu .closer").click(function(){
		$("#mobil-menu").removeClass("open");
		$("#nav-toggle").removeClass("open");
	});
    
    $("#mobil-menu .dropdown-toggle").click(function(){
		
        $(this).siblings("ul.dropdown:first").slideToggle();
	});
	
	/*lightbox.option({
		'resizeDuration': 200,
		'wrapAround': true
	})*/
	
});
